

import { Component, Prop, Vue } from "vue-property-decorator";
import { CalendarEvent } from "vuetify";
import moment from "moment";

import EventComponent from "./Event.vue";

import type { Event, Params } from "../store";

type Tile = {
  name: string;
  default_icon?: string;
};

@Component({
  components: {
    EventComponent
  }
})
export default class CalendarComponent extends Vue {
  @Prop(Object) readonly tile!: Tile;

  type = "week";
  events: Event[] = [];
  weekday = [1, 2, 3, 4, 5, 6, 0];
  focus = moment().format("YYYY-MM-DD");
  eventsDate = "";
  typeChanged = false;

  get appLanguage() {
    return this.$store.getters["app/language"];
  }

  get eventsDateReadable() {
    const _moment = moment(this.eventsDate);
    return _moment.locale(this.appLanguage).format("Do MMMM, Y");
  }

  get reservations() {
    return this.$store.getters["calendar/reservations"]?.map(_ =>
      Object.freeze({
        id: _.id,
        tag: "reservation",
        status: _.status,
        status_id: _.status_id,
        type: this.$t("INTERFACE.BOOKING.RESERVATION"),
        name: _.resource_name,
        start: _.date_from,
        end: _.date_to,
        from: _.time_from,
        to: _.time_to,
        location: `${_.building_street}, ${_.building_complex_zip} ${_.building_complex_city}`,
        access_key: _.access_key
      })
    );
  }

  get tasks() {
    return this.$store.getters["calendar/tasks"]?.map(_ =>
      Object.freeze({
        id: _.id,
        tag: "service",
        status: _.status.name,
        status_id: _.status_id,
        type: this.$t("SERVICES.SERVICE"),
        name: _.order.service.translation.name,
        start: _.date_from,
        end: _.date_from,
        from: _.time_from,
        to: _.time_to,
        location: `${_.order.street}, ${_.order.zip} ${_.order.city}`,
        employee: `${_.employee.first_name} ${_.employee.last_name}`
      })
    );
  }

  async updateEvents({ start, end }) {
    await this.loadEvents({ start, end });

    if (this.typeChanged) {
      this.typeChanged = false;
      return;
    }

    this.eventsDate = "";
    this.events = [];

    if (this.type == "week" && moment().isBetween(start.date, end.date)) {
      this.showEvents({ date: moment().format("YYYY-MM-DD") });
    }
  }

  async fetchRecursive(dispatch: string, commit: string, params = {} as Params, data = []) {
    const { data: response } = await this.$store.dispatch(dispatch, params);

    const meta = response.meta;
    data = data.concat(response.data);

    meta.current_page < meta.last_page
      ? await this.fetchRecursive(dispatch, commit, { ...params, page: params.page + 1 }, data)
      : this.$store.commit(commit, data);
  }

  async loadEvents({ start, end }) {
    const params = {
      "between[from]": start.date,
      "between[to]": end.date,
      page: 1
    };

    await this.fetchRecursive("calendar/fetchReservations", "calendar/setReservations", params);
    await this.fetchRecursive("calendar/fetchTasks", "calendar/setTasks", params);
  }

  async showEvents({ date }) {
    this.focus = date;
    this.eventsDate = moment(date).format("YYYY-MM-DD");

    this.events = this.filteredEvents(date);
    this.events.sort((a, b) => a.from.localeCompare(b.from));
  }

  calendarPrev(): void {
    (this.$refs.calendar as CalendarEvent).prev();
  }

  calendarNext(): void {
    (this.$refs.calendar as CalendarEvent).next();
  }

  changeType(type: string) {
    if (this.eventsDate == "") {
      this.showEvents({ date: moment().format("YYYY-MM-DD") });
    }

    this.typeChanged = true;
  }

  dateIsToday(date: string): boolean {
    return moment(date).isSame(moment().format("YYYY-MM-DD"));
  }

  eventIsset(date: string): boolean {
    return this.filteredEvents(date).length > 0;
  }

  eventsDateSelected(date: string): boolean {
    return moment(this.eventsDate).isSame(moment(date), "day");
  }

  filteredEvents(date: string) {
    return [
      ...this.tasks.filter((_: Event) => _.start == date || _.end == date),
      ...this.reservations.filter((_: Event) => _.start == date || _.end == date)
    ]
  }
}
