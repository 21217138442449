
import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";

type Event = {
  id: number;
  tag: string;
  status: string;
  status_id: number;
  type: string;
  name: string;
  start: string;
  end: string;
  from: string;
  to: string;
  location: string;
  access_key?: string;
  employee?: string;
};

@Component({
  filters: {
    date(value: string, appLanguage: string) {
      if (!value) return;

      return moment(value)
        .locale(appLanguage)
        .format("D MMM, Y");
    }
  }
})
export default class EventComponent extends Vue {
  @Prop(Object) readonly event!: Event;

  dialog = false;
  eventCancellation = false;
  cancelEventReason = null;

  get appLanguage() {
    return this.$store.getters["app/language"];
  }

  get isAfterMoment() {
    return moment().isAfter(moment(`${this.event.start} ${this.event.to}`));
  }

  get formattedDate() {
    const start = this.$options.filters?.date(this.event.start, this.appLanguage);
    const end = this.$options.filters?.date(this.event.end, this.appLanguage);

    return start != end ? `${start} — ${end}` : start;
  }

  closeDialog() {
    this.dialog = false;
    this.eventCancellation = false;
    this.cancelEventReason = null;
  }

  cancelEventForm() {
    this.eventCancellation = true;
  }

  cancelEvent() {
    const formData = {
      id: this.event.id,
      body: {
        reason: this.cancelEventReason
      }
    };

    this.$store.dispatch("calendar/cancelReservation", formData).then(() => {
      this.$emit("load-events", this.event.start);
      this.closeDialog();
    });
  }
}
